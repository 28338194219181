<template>
	<div>
		<table id="3add006037kwc" tablewidth="682" tablecolswidth="95 587" autofit="false" class="table">
			<colgroup colwidth="0.28*" style="width:13.93%"></colgroup>
			<colgroup colwidth="1.72*" style="width:86.07%"></colgroup>
			<tbody class="tbody">
				<tr id="3add277037s0a" style="height:19px">
					<td id="3add277137gm2" rowspan="1" style="background-color:#e5e5e5" colspan="1">
						<p id="3add277237wy9" data-spm-anchor-id="a2c4g.11186623.0.i1.3b637359IkKqmC"><b>省份</b>
						</p>
					</td>
					<td id="3add2773374dv" rowspan="1" style="background-color:#e5e5e5" colspan="1">
						<p id="3add2774371qq"><b>城市</b></p>
					</td>
				</tr>
				<tr id="8723d7b03adsu">
					<td id="8723d7b13aor2" rowspan="1" colspan="1">
						<p id="89dd52603akx4">安徽</p>
					</td>
					<td id="8723d7b33a6zh" rowspan="1" colspan="1">
						<p id="8c2176003a18o">安庆、蚌埠、亳州、巢湖、池州、滁州、阜阳、合肥、淮北、淮南、黄山、六安、马鞍山、铜陵、芜湖、宿县、宿州、宣城</p>
					</td>
				</tr>
				<tr id="3adefc3437814" style="height:21px">
					<td id="3adefc35371rh" rowspan="1" colspan="1">
						<p id="3adefc3637hve">北京</p>
					</td>
					<td id="3adf234037qvo" rowspan="1" colspan="1">
						<p id="3adf234137tve">北京</p>
					</td>
				</tr>
				<tr id="3adf234237sic" style="height:21px">
					<td id="3adf23433708e" rowspan="1" colspan="1">
						<p id="3ae00da337cxw">福建</p>
					</td>
					<td id="3adf234537bzr" rowspan="1" colspan="1">
						<p id="3adf2346371x2">福州、龙岩、南平、宁德、莆田、泉州、三明、厦门、漳州</p>
					</td>
				</tr>
				<tr id="3ae00da637cei" style="height:21px">
					<td id="3ae00da737sbg" rowspan="1" colspan="1">
						<p id="3ae034b037w3n"></p>
						<p id="3ae23086374oh">甘肃</p>
					</td>
					<td id="3ae034b137r4w" rowspan="1" colspan="1">
						<p id="3ae034b237sep">白银、定西、甘南、嘉峪关、金昌、酒泉、兰州、临夏、陇南、平凉、庆阳、天水、武威、张掖</p>
					</td>
				</tr>
				<tr id="3ae23089371kg" style="height:21px">
					<td id="3ae2308a37886" rowspan="1" colspan="1">
						<p id="3ae2308b37vcr">广东</p>
						<p id="3ae25790375z6"></p>
					</td>
					<td id="3ae2308c3725v" rowspan="1" colspan="1">
						<p id="3ae2308d370r1">潮州、东莞、佛山、广州、河源、惠州、江门、揭阳、茂名、梅州、清远、汕头、汕尾、韶关、深圳、阳江、云浮、湛江、肇庆、中山、珠海</p>
					</td>
				</tr>
				<tr id="3ae369043736d" style="height:21px">
					<td id="3ae3690537fr1" rowspan="1" colspan="1">
						<p id="3ae3690637vyn">广西</p>
						<p id="3ae3901137w0g"></p>
					</td>
					<td id="3ae3690737fts" rowspan="1" colspan="1">
						<p id="3ae36908370y9">百色、北海、崇左、防城、防城港、贵港、桂林、河池、贺州、来宾、柳州、南宁、钦州、梧州、玉林</p>
					</td>
				</tr>
				<tr id="3ae4a18037qsf" style="height:21px">
					<td id="3ae4a18137c5x" rowspan="1" colspan="1">
						<p id="3ae4a18237ye5">贵州</p>
						<p id="3ae4a18737xvc"></p>
					</td>
					<td id="3ae4a18337bqn" rowspan="1" colspan="1">
						<p id="3ae4a18437bd3">安顺、毕节、都匀、贵阳、凯里、六盘水、黔东南、黔南、黔西南、铜仁、兴义、遵义</p>
					</td>
				</tr>
				<tr id="3ae516b437jq7" style="height:21px">
					<td id="3ae516b537lmy" rowspan="1" colspan="1">
						<p id="3ae516b6373i0">海南</p>
					</td>
					<td id="3ae516b737a7d" rowspan="1" colspan="1">
						<p id="3ae516b837h7h">海口</p>
						<div type="note" id="ac7e37b7b13lq" class="note note-note">
							<div class="note-icon-wrapper"><i class="icon-note note note"></i><strong>说明
								</strong></div>
							<div class="noteContentSpan">
								<p id="7c8603126a8e1">海南省由于通信管理的历史因素，省内归属地仅有一个<b>海口</b>，即归属地市仅支持<b>海口</b>。</p>
							</div>
						</div>
					</td>
				</tr>
				<tr id="3ae516b9379i2" style="height:21px">
					<td id="3ae516ba37c8e" rowspan="1" colspan="1">
						<p id="3ae516bb37vk1">河北</p>
						<p id="3ae516c037x0i"></p>
					</td>
					<td id="3ae516bc37rez" rowspan="1" colspan="1">
						<p id="3ae516bd371tb">保定、沧州、承德、邯郸、衡水、廊坊、秦皇岛、石家庄、唐山、邢台、张家口</p>
					</td>
				</tr>
				<tr id="3ae58be137j6x" style="height:21px">
					<td id="3ae58be23793f" rowspan="1" colspan="1">
						<p id="3ae58be3372ye">河南</p>
						<p id="3ae58be837s3s"></p>
					</td>
					<td id="3ae58be437hbl" rowspan="1" colspan="1">
						<p id="3ae58be537nbi">安阳、鹤壁、济源、焦作、开封、洛阳、漯河、南阳、平顶山、濮阳、三门峡、商丘、新乡、信阳、许昌、郑州、周口、驻马店</p>
					</td>
				</tr>
				<tr id="3ae69d5937x43" style="height:21px">
					<td id="3ae69d5a37rzm" rowspan="1" colspan="1">
						<p id="3ae69d5b37j81">黑龙江</p>
						<p id="3ae69d6037nqx"></p>
					</td>
					<td id="3ae69d5c37bxp" rowspan="1" colspan="1">
						<p id="3ae69d5d37dap">大庆、大兴安岭、哈尔滨、鹤岗、黑河、鸡西、佳木斯、牡丹江、七台河、齐齐哈尔、双鸭山、绥化、伊春</p>
					</td>
				</tr>
				<tr id="3ae73994371ov" style="height:21px">
					<td id="3ae7399537tiy" rowspan="1" colspan="1">
						<p id="3ae7399637no3">湖北</p>
						<p id="3ae7399b370sk"></p>
					</td>
					<td id="3ae7399737iwf" rowspan="1" colspan="1">
						<p id="3ae7399837hmn">鄂州、恩施、黄冈、黄石、荆门、荆州、十堰、随州、武汉、仙桃、咸宁、襄阳、孝感、宜昌、天门、潜江</p>
					</td>
				</tr>
				<tr id="3ae7d5d5379bt" style="height:21px">
					<td id="3ae7d5d637vfa" rowspan="1" colspan="1">
						<p id="3ae7d5d7371wh">湖南</p>
						<p id="3ae7d5dc372ra"></p>
					</td>
					<td id="3ae7d5d8374sz" rowspan="1" colspan="1">
						<p id="3ae7d5d937yfr">常德、郴州、衡阳、怀化、吉首、娄底、邵阳、湘潭、益阳、永州、岳阳、张家界、长沙、株洲、湘西</p>
					</td>
				</tr>
				<tr id="3ae8721e37kl4" style="height:21px">
					<td id="3ae8721f37riw" rowspan="1" colspan="1">
						<p id="3ae87220376hn">吉林</p>
						<p id="3ae8992237u85"></p>
					</td>
					<td id="3ae8722137s16" rowspan="1" colspan="1">
						<p id="3ae8722237fis">白城、白山、吉林、辽源、四平、松原、通化、延边、长春</p>
					</td>
				</tr>
				<tr id="3ae8e74737xgs" style="height:21px">
					<td id="3ae8e74837iah" rowspan="1" colspan="1">
						<p id="3ae8e74937i7r"> 江苏</p>
						<p id="3ae8e74e37awk"></p>
					</td>
					<td id="3ae8e74a37t5u" rowspan="1" colspan="1">
						<p id="3ae8e74b37oo9">常州、淮安、淮阴、连云港、南京、南通、苏州、泰州、无锡、宿迁、徐州、盐城、扬州、镇江</p>
					</td>
				</tr>
				<tr id="3ae9aa9e37t99" style="height:21px">
					<td id="3ae9d1a037hdu" rowspan="1" colspan="1">
						<p id="3ae9d1a137en3">江西</p>
						<p id="3ae9d1a6376bb"></p>
					</td>
					<td id="3ae9d1a237w9p" rowspan="1" colspan="1">
						<p id="3ae9d1a337cbv">抚州、赣州、吉安、景德镇、九江、南昌、萍乡、上饶、新余、宜春、鹰潭</p>
					</td>
				</tr>
				<tr id="3aea46d737na7" style="height:21px">
					<td id="3aea46d837biy" rowspan="1" colspan="1">
						<p id="3aea46d937nyl">辽宁</p>
						<p id="3aea46de373oo"></p>
					</td>
					<td id="3aea46da37uk1" rowspan="1" colspan="1">
						<p id="3aea46db37o9n">鞍山、本溪、朝阳、大连、丹东、抚顺、阜新、葫芦岛、锦州、辽阳、盘锦、沈阳、铁岭、营口</p>
					</td>
				</tr>
				<tr id="3aeb0a2937sy4" style="height:21px">
					<td id="3aeb0a2a37ofp" rowspan="1" colspan="1">
						<p id="3aeb0a2b37eg3">内蒙古</p>
					</td>
					<td id="3aeb0a2c37u77" rowspan="1" colspan="1">
						<p id="3aeb0a2d37v0u">
							呼伦贝尔、巴音郭楞、阿拉善、巴彦淖尔、包头、赤峰、鄂尔多斯、海拉尔、呼和浩特、通辽、乌海、乌兰察布、锡林郭勒、锡林浩特、兴安盟、伊克昭</p>
					</td>
				</tr>
				<tr id="3aebcd77374yo" style="height:21px">
					<td id="3aebcd7837q1w" rowspan="1" colspan="1">
						<p id="3aebcd7937cfe">宁夏</p>
					</td>
					<td id="3aebcd7a37i7z" rowspan="1" colspan="1">
						<p id="3aebcd7b37too">固原、石嘴山、吴忠、银川、中卫</p>
					</td>
				</tr>
				<tr id="3aebf48e3747o" style="height:21px">
					<td id="3aec1b9037ry5" rowspan="1" colspan="1">
						<p id="3aec1b9137u5j">青海</p>
						<p id="3aec1b9637lng"></p>
					</td>
					<td id="3aec1b9237plr" rowspan="1" colspan="1">
						<p id="3aec1b9337me2">格尔木、果洛、海北、海东、海南、海西、黄南、西宁、玉树</p>
					</td>
				</tr>
				<tr id="3aec90cd37hxe" style="height:21px">
					<td id="3aecb7d037cu6" rowspan="1" colspan="1">
						<p id="3aecb7d1375ye">山东</p>
					</td>
					<td id="3aecb7d2379ch" rowspan="1" colspan="1">
						<p id="3aecb7d337iku">滨州、德州、东营、菏泽、济南、济宁、莱芜、聊城、临沂、青岛、日照、泰安、威海、潍坊、烟台、枣庄、淄博</p>
					</td>
				</tr>
				<tr id="3aed7b2937yci" style="height:21px">
					<td id="3aed7b2a37slf" rowspan="1" colspan="1">
						<p id="3aed7b2b37tnp">山西</p>
						<p id="3aeda23137rhk"></p>
					</td>
					<td id="3aed7b2c37l0j" rowspan="1" colspan="1">
						<p id="3aed7b2d37lt6">大同、晋城、晋中、临汾、吕梁、朔州、太原、忻州、阳泉、运城、长治</p>
					</td>
				</tr>
				<tr id="3aee176137l54" style="height:21px">
					<td id="3aee176237msk" rowspan="1" colspan="1">
						<p id="3aee1763372f9">陕西</p>
					</td>
					<td id="3aee176437a94" rowspan="1" colspan="1">
						<p id="3aee176537uis">安康、宝鸡、汉中、商洛、铜川、渭南、西安、咸阳、延安、榆林</p>
					</td>
				</tr>
				<tr id="3aee8c9437wt4" style="height:21px">
					<td id="3aee8c9537fyj" rowspan="1" colspan="1">
						<p id="3aee8c9637vdm">上海</p>
					</td>
					<td id="3aee8c9737h9j" rowspan="1" colspan="1">
						<p id="3aee8c9837zo4">上海</p>
					</td>
				</tr>
				<tr id="3aee8c993763l" style="height:42px">
					<td id="3aee8c9a3758l" rowspan="1" colspan="1">
						<p id="3aee8c9b373jx">四川</p>
					</td>
					<td id="3aee8c9c37wq4" rowspan="1" colspan="1">
						<p id="3aee8c9d37dds">
							阿坝、凉山、巴中、成都、达川、达州、德阳、甘孜、广安、广元、康定、乐山、泸州、马尔康、眉山、绵阳、南充、内江、攀枝花、遂宁、西昌、雅安、宜宾、资阳、自贡</p>
					</td>
				</tr>
				<tr id="3af0133037ynt" style="height:21px">
					<td id="3af01331374pw" rowspan="1" colspan="1">
						<p id="3af0133237gc5">天津</p>
					</td>
					<td id="3af0133337ual" rowspan="1" colspan="1">
						<p id="3af0133437ap9">天津</p>
					</td>
				</tr>
				<tr id="3af01335377jk" style="height:21px">
					<td id="3af0133637ftg" rowspan="1" colspan="1">
						<p id="3af0133737lwx">西藏</p>
					</td>
					<td id="3af0133837iea" rowspan="1" colspan="1">
						<p id="3af0133937ieb">阿里、昌都、拉萨、林芝、那曲、日喀则、山南</p>
					</td>
				</tr>
				<tr id="3af0615c37lw3" style="height:21px">
					<td id="3af08860376ei" rowspan="1" colspan="1">
						<p id="3af0886137nif">新疆</p>
						<p id="3af0886637gkg"></p>
					</td>
					<td id="3af0886237dp0" rowspan="1" colspan="1">
						<p id="3af08863372qd">阿克苏、阿勒泰、博乐、昌吉、哈密、和田、喀什、克拉玛依、克州、库尔勒、奎屯、石河子、塔城、吐鲁番、乌鲁木齐、伊犁、博尔塔拉</p>
					</td>
				</tr>
				<tr id="3af14bb437hg6" style="height:67px">
					<td id="3af14bb537czf" rowspan="1" colspan="1">
						<p id="3af14bb637fmc">云南</p>
						<p id="3af14bbb37c0k"></p>
					</td>
					<td id="3af14bb737y7i" rowspan="1" colspan="1">
						<p id="3af14bb837pth">保山、楚雄、大理、德宏、迪庆、红河、开远、昆明、丽江、临沧、怒江、普洱、曲靖、思茅、文山、西双版纳、玉溪、昭通</p>
					</td>
				</tr>
				<tr id="3af28434371jl" style="height:21px">
					<td id="3af2843537l7f" rowspan="1" colspan="1">
						<p id="3af2843637ueg">浙江</p>
						<p id="3af2843b37ll5"></p>
					</td>
					<td id="3af28437371ws" rowspan="1" colspan="1">
						<p id="3af284383773z">杭州、湖州、嘉兴、金华、丽水、宁波、衢州、绍兴、台州、温州、舟山</p>
					</td>
				</tr>
				<tr id="3af3207437mnn" style="height:21px">
					<td id="3af3207537yya" rowspan="1" colspan="1">
						<p id="3af3207637ff7">重庆</p>
					</td>
					<td id="3af3207737dx0" rowspan="1" colspan="1">
						<p id="3af3207837qfv">重庆</p>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
</script>

<style scoped>
	.table {
		border: solid 1px #EEE;
	}
	.tbody tr {
		border-bottom: solid 1px #EEE;
		/* line-height: 60px; */
	}
	.tbody tr td {
		padding: 10px 0 0 10px;
		border-right: solid 1px #EEE;
	}
</style>
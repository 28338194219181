<template>
	<div>
		<Header :title="isEdit ? '修改手机号预警' : '新增手机号预警'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }" @finish="onSubmit">
				<a-row>
					<a-col :span="24">
						<a-form-item name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
							<a-select :disabled="isSee || isEdit" placeholder="请选择" v-model:value="modelRef.organizationId" @change="getAllCinemaList">
								<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
									{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>

						<a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
							<a-select :disabled="isSee || isEdit" placeholder="请选择" v-model:value="modelRef.cinemaId">
								<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" :disabled="cinemaIds.includes(item.id)">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>

						<a-form-item label="预警开关" name="isDisabled" :rules="[{required: true, message: '请选择预警开关'}]">
							<a-radio-group v-model:value="modelRef.isDisabled" :disabled="isSee">
								<a-radio :value="1">关闭预警</a-radio>
								<a-radio :value="0">开启预警</a-radio>
							</a-radio-group>
						</a-form-item>

						<a-form-item label="预警时间段">
							<a-button type="primary" :disabled="isSee" @click="onAddTime">新增时间段</a-button>
							<div style="margin-top: 10px;" v-for="(item, index) in timeList" :key="index">
								<a-range-picker v-model:value="item.time" :disabled="isSee" showTime></a-range-picker> 
								<a-button v-if="timeList.length > 1" style="margin-left: 10px;" danger :disabled="isSee" @click="onDelTime(index)">删除</a-button>
							</div>
						</a-form-item>

						<a-form-item label="预警手机号列表" name="warnPhone" :rules="[{required: false, message: '必选项不允许为空'}]">
							<a-textarea v-model:value="modelRef.warnPhone" :disabled="isSee" placeholder="请输入手机号,多个手机号请用英文逗号分割"></a-textarea>
							<!-- <a-button type="primary" @click="onAddPhone">新增预警手机号</a-button>
							<div style="margin-top: 10px;" v-for="(item, index) in phoneList" :key="index">
								<a-row>
									<a-col :span="14">
										<a-input placeholder="请输入" />
									</a-col>
									<a-col :span="4" v-if="phoneList.length > 1">
										<a-button style="margin-left: 10px;" danger
											@click="onDelPhone(index)">删除</a-button>
									</a-col>
								</a-row>
							</div> -->
						</a-form-item>

						<a-form-item label="接收预警短信手机号" name="receivePhone" :rules="[{required: true, message: '必选项不允许为空'}]">
							<a-textarea v-model:value="modelRef.receivePhone" :disabled="isSee" placeholder="请输入手机号,多个手机号请用英文逗号分割"></a-textarea>
							<!-- <a-button type="primary" @click="onAddMobile">新增接收预警手机号</a-button>
							<div style="margin-top: 10px;" v-for="(item, index) in mobileList" :key="index">
								<a-row>
									<a-col :span="14">
										<a-input placeholder="请输入" />
									</a-col>
									<a-col :span="4" v-if="mobileList.length > 1">
										<a-button style="margin-left: 10px;" danger
											@click="onDelMobile(index)">删除</a-button>
									</a-col>
								</a-row>
							</div> -->
						</a-form-item>

						<a-form-item label="预警手机号归属地" name="phoneAttribute" :rules="[{required: false, message: '必选项不允许为空'}]">
							<a-button style="margin-bottom: 10px;" type="primary" @click="showImg = true">查看归属地信息</a-button>
							<a-textarea v-model:value="modelRef.phoneAttribute" :disabled="isSee" placeholder="请输入归属地,多个归属地请用英文逗号分割;例如:北京北京,广东广州"></a-textarea>
							<!-- <a-button type="primary" @click="onAddAddress">新增归属地</a-button> 
							<a style="margin-left: 30px;" type="link" @click="showImg = true">查看归属地信息</a>
							<a-row style="margin-top: 10px;" v-for="(item, index) in addressList" :key="index">
								<a-col :span="14">
									<a-input placeholder="请输入归属地,例如:北京北京" />
								</a-col>
								<a-col :span="4" v-if="addressList.length > 1">
									<a-button style="margin-left: 10px;" danger
										@click="onDelAddress(index)">删除</a-button>
								</a-col>
							</a-row> -->
						</a-form-item>

						<a-form-item label="是否使用票务系统售票" name="isTicketMode" extra="开启时,命中预警手机号的用户则使用票务系统出票">
							<a-radio-group v-model:value="modelRef.isTicketMode" :disabled="isSee">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						<!-- <a-form-item label="预警购票模式" name="buyTicketMode"
							:rules="[{required: true, message: '必选项不允许为空'}]">
							<a-radio-group :disabled="isSee" v-model:value="modelRef.buyTicketMode" button-style="solid">
								<a-radio-button :value="1">票务系统出票</a-radio-button>
								<a-radio-button :value="2">云端系统出票</a-radio-button>
							</a-radio-group>
						</a-form-item> -->
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-modal title="归属地信息表" width="850px" v-model:visible="showImg" :footer="null">
				<belong />
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import addressSelect from '@/components/addressSelect/index.vue';
	import areaSelect from '@/components/areaSelect/index.vue';
	import belong from './belong.vue';
	import {
		getOrganizationList,
		getCinemaList,
		getCinemaWarnDetail,
		updateCinemaWarn,
		saveCinemaWarn,
		getCinemaIds
	} from '@/service/modules/cinema.js';
	export default {
		components: {
			Header,
			addressSelect,
			areaSelect,
			belong
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		created() {
			this.getCinemaIds();
			this.getOrganizationList();
			if (this.id) {
				this.getData();
			}
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				cinemaIds: [],
				modelRef: {
					isDisabled: 0,
					isTicketMode: 0
				},
				timeList: [{
					time: []
				}],
				phoneList: [{}],
				mobileList: [{}],
				addressList: [{}],
				fileList: [],
				showImg: false
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getCinemaIds() {
				let ret = await getCinemaIds({});
				if (ret.code === 200) {
					this.cinemaIds = ret.data;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaWarnDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.timeList) {
							this.timeList = JSON.parse(ret.data.timeList).map(item => {
								return {
									time: [this.moment(item.startTime * 1000), this.moment(item.endTime * 1000)]
								}
							});
						}
						
						this.getAllCinemaList(ret.data.organizationId);
						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				// if (!this.timeList[0].time || this.timeList[0].time.length == 0) {
				// 	this.$message.warn('请选择【预警时间段】');
				// 	return;
				// }
				if (!postData.warnPhone && !postData.phoneAttribute) {
					this.$message.warn('请填写【预警手机号】或【预警手机归属地】信息');
					return;
				}
				let timeList = [];
				this.timeList.forEach(item => {
					if (item.time && item.time.length !== 0) {
						timeList.push({
							startTime: item.time[0].unix(),
							endTime: item.time[1].unix()
						})
					}
				})
				console.log(timeList);
				if (timeList.length) {
					postData.timeList = timeList;
				} else {
					postData.timeList = "";
				}
				try {
					let ret;
					this.loading = true;
					if (this.isEdit && this.id) {
						postData.id = this.id;
						ret = await updateCinemaWarn(postData);
					} else {
						ret = await saveCinemaWarn(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				this.organizationList = ret.data.list || [];
			},
			onAddTime() {
				this.timeList.push({
					time: []
				});
			},
			onDelTime(index) {
				this.timeList.splice(index, 1);
			},
			onAddPhone() {
				this.phoneList.push({});
			},
			onDelPhone(index) {
				this.phoneList.splice(index, 1);
			},
			onAddMobile() {
				this.mobileList.push({});
			},
			onDelMobile(index) {
				this.mobileList.splice(index, 1);
			},
			onAddAddress() {
				this.addressList.push({});
			},
			onDelAddress(index) {
				this.addressList.splice(index, 1);
			},
			onAddress(data) {
				if (data) {
					this.modelRef.province = data[0].fullname;
					this.modelRef.city = data[1].fullname;
					if (data.length === 3) {
						this.modelRef.area = data[2].fullname;
					}
				} else {
					this.modelRef.province = '';
					this.modelRef.city = '';
					this.modelRef.area = '';
				}
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.serviceImg = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			}
		}
	}
</script>

<style scoped>
	.ui-custom__btnInput {
		position: relative;
	}

	.ui-custom__btn {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	}
</style>